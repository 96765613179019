<template>
	<Nav />
	
	  <div class="parallax-container valign-wrapper">
		<div class="section no-pad-bot">
		  <div class="container">
			<div class="row center">
			  <h5 class="header col s12 light"><img src="@/../public/img/comesa_logo_blue.fw.png" class="responsive-img" style="max-height:230px; position: relative; top: -50px;"></h5>
			</div>
		  </div>
		</div>
		<div class="parallax"><img src="@/../public/img/banner-949946_1920.jpg" alt="Unsplashed background img 1"></div>
	  </div>
				<div id="introduction" class="section scrollspy">
					<div class="row ">
						<div class="col s12">
							<div class="col s12 m12 l6 right">
								<div class="col s12">
									<div class="col s12 " style="padding:10px; margin:10px;">
										<div class="col s12">
											<div class="col s12">
												  <div class="row">
														
														<form v-if="online_status" class="col s12 card-panel blue darken-3 hoverable">
														  <div class="row">
															<div class="col s12">
															  <h4><b class="white-text">{{ languages[0] }}</b></h4><!---->
															</div>
														  </div>
														  <div class="row">
															  <div class="col s12">
																  <div class="col s12 white lighten-4" v-if="pre_response">
																	<div class="input-field col s12">
																	  <input v-model="eco_verification" id="eco_verification" type="text" class="validate">
																	  <label for="eco_verification" class="blue-grey-text text-darken-4">{{ languages[1] }}</label>
																	  <span class="helper-text" v-html="input_validation"></span>
																	</div>
																	  <div class="row">
																		<div class="col s12">
																			<a v-on:click="verifyECO()" class="waves-effect red darken-2 waves-light btn-large white-text right">{{ languages[2] }}</a>
																		</div>
																	  </div>
																  </div>
																  
																  <div class="col s12 white lighten-4" v-if="during_response">
																		<div class="col s12">&nbsp;</div>
																		<div class="col s12">
																			<div class="preloader-wrapper big active">
																			  <div class="spinner-layer spinner-blue">
																				<div class="circle-clipper left">
																				  <div class="circle"></div>
																				</div>
																			  </div>

																			  <div class="spinner-layer spinner-red">
																				<div class="circle-clipper left">
																				  <div class="circle"></div>
																				</div>
																			  </div>

																			  <div class="spinner-layer spinner-yellow">
																				<div class="circle-clipper left">
																				  <div class="circle"></div>
																				</div>
																			  </div>

																			  <div class="spinner-layer spinner-green">
																				<div class="circle-clipper left">
																				  <div class="circle"></div>
																				</div>
																			  </div>
																			</div>
																		</div>
																  </div>
																  <div class="col s12 white lighten-4" v-if="post_response">
																	<p>&nbsp;<br>{{ user_err }}</p><br>&nbsp;
																	<a v-on:click="resetLogin()" class="waves-effect red darken-2 waves-light btn-large white-text">{{ languages[3] }}</a>
																	<br>&nbsp;<br>
																	  <div class="row">
																		<div class="col s12">
																			<span>{{ languages[4] }}? <router-link to="/recovery" class="green-text text-darken-4">&nbsp;<b>{{ languages[5] }}</b></router-link></span><br>
																			<span>{{ languages[6] }}? <router-link to="/register" class="red-text text-darken-4">&nbsp;<b>{{ languages[7] }}</b></router-link></span>
																		</div>
																	  </div>
																  </div>
															  </div>
														  </div>
														</form>
														<Login v-else></Login>
												  </div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col s12 m12 l6 left">
								<div class="col s12">
									<div class="col s12" v-if="online_status">
										<router-link class="btn-large blue darken-2 white-text hoverable" to="/dashboard">{{ languages[8] }}</router-link>
									</div>
									<div class="col s12 white">
										<div class="row">
											<div class="col s12">
												<h4><b>{{ languages[9] }}</b></h4>
											</div>
										</div>
										<div class="row">
											<div class="col s11">
												<p><i class="small left material-icons green-text darken-2" style="position:relative; top:10px;">check_circle</i> <b>{{ languages[10] }}</b></p>
												<p>{{ languages[11] }}</p>
											</div>
										</div>
										<div class="diveder"></div>
										<div class="row">
											<div class="col s11">
												<p><i class="small left material-icons green-text darken-2" style="position:relative; top:10px;">check_circle</i> <b>{{ languages[12] }}</b></p>
												<p>{{ languages[13] }}</p>
											</div>
										</div>
										<div class="diveder"></div>
										<div class="row">
											<div class="col s11">
												<p><i class="small left material-icons green-text darken-2" style="position:relative; top:10px;">check_circle</i> <b>{{ languages[14] }}</b> </p>
												<p>{{ languages[15] }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="divider row">&nbsp;<br><br></div>

				<div id="steps" class="section scrollspy">
					<div class="row">
						<div class="col s12 m6 l3">
							<div class="col s12 hoverable" style="min-height:250px;">
								<h5>{{ languages[16] }}</h5>
								<h4><b>{{ languages[17] }}</b></h4>
								{{ languages[18] }}
							</div>
						</div>
						<div class="col s12 m6 l3">
							<div class="col s12 hoverable" style="min-height:250px;">
								<h5>{{ languages[19] }}</h5>
								<h4><b>{{ languages[20] }}</b></h4>
								{{ languages[21] }}
							</div>
						</div>
						<div class="col s12 m6 l3">
							<div class="col s12 hoverable" style="min-height:250px;">
								<h5>{{ languages[22] }}</h5>
								<h4><b>{{ languages[23] }}</b></h4>
								{{ languages[24] }}
							</div>
						</div>
						<div class="col s12 m6 l3">
							<div class="col s12 hoverable" style="min-height:250px;">
								<h5>{{ languages[25] }}</h5>
								<h4><b>{{ languages[26] }}</b></h4>
								{{ languages[27] }}
							</div>
						</div>
					</div>
				</div>
				

	  <div class="parallax-container valign-wrapper">
		<div class="section no-pad-bot">
		  <div class="container">
			<div class="row center">
			  <h5 class="header col s12 light"><img src="@/../public/img/comesa_logo_white.fw.png" class="responsive-img" style="max-height:330px;"></h5>
			</div>
		  </div>
		</div>
		<div class="parallax"><img src="@/../public/img/clouds-2085111_1920.jpg" alt="Unsplashed background img 1"></div>
	  </div>
	  

	  <div class="container">
		<div class="section">

		  <!--   Icon Section   -->
		  <div class="row">
			<div class="col s12 m4">
			  <div class="icon-block">
				<h2 class="center brown-text"><i class="material-icons">public</i></h2>
				<h5 class="center">{{ languages[28] }}</h5>

				<p class="light">{{ languages[29] }}</p>
			  </div>
			</div>

			<div class="col s12 m4">
			  <div class="icon-block">
				<h2 class="center brown-text"><i class="material-icons">flash_on</i></h2>
				<h5 class="center">{{ languages[30] }}</h5>

				<p class="light">{{ languages[31] }}</p>
			  </div>
			</div>

			<div class="col s12 m4">
			  <div class="icon-block">
				<h2 class="center brown-text"><i class="material-icons">important_devices</i></h2>
				<h5 class="center">{{ languages[32] }}</h5>

				<p class="light">{{ languages[33] }}</p>
			  </div>
			</div>
		  </div>

		</div>
	  </div>

	  <div class="parallax-container valign-wrapper">
		<div class="section no-pad-bot">
		  <div class="container">
			<div class="row center">
			  <h5 class="header col s12 light"></h5>
			</div>
		  </div>
		</div>
		<div class="parallax"><img src="@/../public/img/truck-455340_1280.jpg" alt="Unsplashed background img 1"></div>
	  </div>

				<div id="features" class="section scrollspy">
					<div class="row">
						<div class="col s12 m6">
							<img class="responsive-img" src="@/../public/img/img-1.png">
						</div>
						<div class="col s12 m6">
							<div class="row">
								<div class="col s6">
									<i class="material-icons large blue-grey-text darken-1">event_busy</i><h5>{{ languages[34] }}</h5>
									{{ languages[35] }}
								</div>
								<div class="col s6">
									<i class="material-icons large blue-grey-text darken-1">business</i><h5>{{ languages[36] }}</h5>
									{{ languages[37] }}
								</div>
							</div>
							<div class="row">
								<br>
							</div>
							<div class="row">
								<div class="col s6">
									<i class="material-icons large blue-grey-text darken-1">security</i><h5>{{ languages[38] }}</h5>
									{{ languages[39] }}
								</div>
								<div class="col s6">
									<i class="material-icons large blue-grey-text darken-1">public</i><h5>{{ languages[40] }}</h5>
									{{ languages[41] }}
								</div>
							</div>
							<div class="row">
								<br>
							</div>
							<div class="row">
								<div class="col s6">
									<i class="material-icons large blue-grey-text darken-1">account_balance</i><h5>{{ languages[42] }}</h5>
									{{ languages[43] }}
								</div>
								<div class="col s6">
									<i class="material-icons large blue-grey-text darken-1">poll</i><h5>{{ languages[44] }}</h5>
									{{ languages[45] }}
								</div>
							</div>
						</div>
					</div>
				</div>

	  <div class="parallax-container valign-wrapper">
		<div class="section no-pad-bot">
		  <div class="container">
			<div class="row center">
				<div class="col s12 show-on-medium-and-down hide-on-large-only"><h5 class="header col s12 light" style="position: relative; top: -140px;"><img src="@/../public/img/COMESA_logo.fw.png" class="responsive-img" style="max-height:150px;"><br><br>{{ languages[46] }}</h5></div>
				<div class="col s12 m4 hide-on-med-and-down show-on-large"><h5 class="header col s12 light"><img src="@/../public/img/COMESA_logo.fw.png" class="responsive-img" style="max-height:200px;"></h5></div>
				<div class="col s12 m8 hide-on-med-and-down show-on-large"><h4 class="header col s12 light"><br>{{ languages[47] }}</h4></div>
			</div>
		  </div>
		</div>
		<div class="parallax"><img src="@/../public/img/water-2072218_1920.jpg" alt="Unsplashed background img 1"></div>
	  </div>

	  <div class="container">
		<div class="section">

		  <div class="row">
			<div class="col s12 center">
			  <h3><i class="mdi-content-send brown-text"></i></h3>
			  <h4>{{ languages[48] }}</h4>
				<div class="col s12">
					<div class="col s12 m3 hoverable">
						<img src="img/partner-1.jpg" class="responsive-img">
					</div>
					<div class="col s12 m3 hoverable">
						<img src="img/partner-2.jpg" class="responsive-img">
					</div>
					<div class="col s12 m3 hoverable">
						<img src="img/partner-3.jpg" class="responsive-img">
					</div>
					<div class="col s12 m3 hoverable">
						<img src="img/partner-4.jpg" class="responsive-img">
					</div>
				</div>
			</div>
		  </div>

		</div>
	</div>
	<AppFooter />
</template>

<script>
// @ is an alias to /src
import router from '@/router'

import axios from 'axios'

import M from '@cutls/materialize-css';
import IndexedDBHelper from '@/assets/js/IndexedDB'

import Nav from '@/components/Nav.vue'
import Login from '@/components/Login.vue'

import AppFooter from '@/components/AppFooter.vue'
import Configs from '@/assets/js/Configs'
import apis from '@/configs/apis.json';
import countries from '@/configs/countries.json';
import comesa_countries from '@/configs/comesa_countries.json';
import language from '@/configs/language.json';


export default {
	name: 'HomeView',
	components: {
		Nav,
		Login,
		AppFooter
	},
	data() {
		return {
			db: null, //the database helper instance
			login_form: true,
			verify_form: false,
			input_validation: "",
			eco_verification: "",
			user_email: "",
			user_password: "",
			user_err: "",
			pre_response: true,
			during_response: false,
			post_response: false,
			error_response: false,
			online_status: false,
			
			
			menu_status: "",
			side_bar: "",
			main_area: '',
			side_bar_style: '',
			main_area_height: '',
			main_area_width: '',
			mylang: 'en',
			languages: [],
				
        };
    },
	async created() { 
	this.db = new IndexedDBHelper("my-eco-db", 1); await this.db.open(); }, async mounted () {
		let current_user_arr = await this.db.getStorageItem("current_user");
		let token = await this.db.getStorageItem("token");
		
		this.initFunctions();
		
		if((token != "" && token != null )&& ('first_name' in current_user_arr && 'last_name' in current_user_arr) && (current_user_arr.first_name != "" && current_user_arr.first_name != null ) && (current_user_arr.last_name != "" && current_user_arr.last_name != null ))
		{
			this.login_form = false,
			this.verify_form = true,
			this.online_status = true,
			this.menuStatus(),
			M.AutoInit()
		}
		else
		{
			this.login_form = true,
			this.verify_form = false,
			this.online_status = false,
			this.menuStatus(),
			M.AutoInit()
		}
		
		
	},  
	watch: {
		eco_verification: function() {
		  if(this.eco_verification != "" && this.eco_verification != null)
		  {
			this.validateInput();
		  }
		},
	},
	methods: {	
		async initFunctions(){
			let mylang = await this.db.getStorageItem("mylang");
			if(mylang=="" || mylang==null || mylang===undefined)
			{
				mylang = 'en';
			}
			this.mylang = mylang;
			this.languages = language[mylang].view__index;
		},
		async inputValidation(){
			let input_data = this.eco_verification.toUpperCase();
			let error_txt = "";
			
			const input_data_withoutFirst2 = input_data.slice(2);
			
			let country_codes = [];
			if(this.db.getStorageItem("defaults") !== null && this.db.getStorageItem("defaults") !== "")
			{
				let defaults = await this.db.getStorageItem("defaults");
				countries = defaults.countries.output;
				let a=0;
				country_codes = [];
				for(a=0; a<countries.length; a++)
				{
					country_codes[a] = countries[a].country_code;
				}
			}
			
			let num=0;
			let b=0;
			for(b=0; b<country_codes.length; b++)
			{
				if(input_data.startsWith(country_codes[b]))
				{
					num = num+1;
				}
			}
			
			if(num>0)
			{
				
			}
			else
			{
				error_txt += "<p>The first two characters of your eCO Serial Number are incorrect.</p>";
			}
			
			let check_nums = input_data_withoutFirst2.match(/^[0-9]+$/) != null;
			
			if(check_nums==false)
			{
				error_txt += "<p>Your eCO Serial Number is not formated correctly.</p>";
			}
			
			if(input_data.length==12)
			{
				
			}
			else
			{
				error_txt += "<p>Your eCO Serial Number is not of the correct length.</p>";
			}
				
			return error_txt;
		},
		async menuStatus(status=''){
			let myConfigs = new Configs();
			let styles = await myConfigs.menuStyles(status);
			
			this.menu_status = styles.menu_status;
			this.side_bar = styles.side_bar;
			this.main_area = styles.main_area;
			this.side_bar_style = styles.side_bar_style;
			this.main_area_height = styles.main_area_height;
			this.main_area_width = styles.main_area_width;
		},
		validateInput(){
			let error_txt = this.inputValidation();
			if(error_txt=="")
			{
				this.input_validation = "You got it!";
				this.verifyECO(); 
			}
			else
			{
				this.input_validation = error_txt;
			}
		},
		verifyECO(){
			this.db.setStorageItem("eco_verification",this.eco_verification);
			setTimeout(function() {
				router.push('/verify_eco'); 
			}, 1000);
		},
		resetLogin(){
			this.pre_response = true;
			this.during_response = false;
			this.post_response = false;
			this.error_response = false;
			this.user_email = '';
			this.user_password = '';
			this.my_users = '';
		}
	}
}
</script>
