<template>
	<Requests :token="token" :key="my_key" request_type="GET" :url="my_check_url" :input_data="[]" notification_type="nil" @response="getResponse" v-if="operation=='check'" />
	<Requests :token="token" :key="my_key2" request_type="GET" :url="my_end_url" :input_data="[]" notification_type="nil" @response="getResponse2" v-if="operation=='end'" />

	<div class="navbar-fixed" v-if="closed_nav">
		<nav class="nav-extended blue darken-2">
		  
		  <div class="nav-wrapper">
			<a href="#" data-target="slide-out" class="sidenav-trigger show-on-small hide-on-med-and-up" v-on:click="openSideBar()"><i class="material-icons white-text">menu</i></a>
			<a href="#" class="left" style="max-width:70px;"><img src="@/../public/img/COMESA_logo.fw.png" class="left z-depth-3 btn-floating btn-large" style="position:relaitve; top:3px; width:65px; height: 65px; margin-left:10px;"></a>
			  <ul class="right">
				<li><a href="#" v-on:click="signOut()" class="black-text text-darken-4">{{ languages[0] }}</a></li>
				<li><a href="#notifications_modal" class="modal-trigger"><i class="material-icons black-text text-darken-4">notifications</i></a></li>
				<li><a href="#language_modal" class="modal-trigger"><i class="material-icons black-text text-darken-4">language</i></a></li>
			  </ul>
		  </div>
		</nav>
		  <!-- Modal Structure -->
		  <div id="notifications_modal" class="modal modal-fixed-footer">
			<div class="modal-content">
			  <a href="#!" class="modal-close right"><i class="material-icons red-text text-darken-4">close</i></a>
			  <h4>{{ languages[1] }}</h4><hr>
			  <DashboardNotifications />
			  <br>
			  <router-link to="/notifications" class="btn btn-large center-align">{{ languages[2] }}</router-link>
			</div>
		  </div>
		   
		  <!-- Modal Structure -->
		  <div id="language_modal" class="modal" style="width:230px;">
			<div class="modal-content">
			  <a href="#!" class="modal-close right"><i class="material-icons red-text text-darken-4">close</i></a>
			  <h5><b>Change Language / Changer de langue / تغيير اللغة</b></h5><hr>
			  <ul>
				<li><a href="#" v-on:click="changeLanguage('en')">English</a></li>
				<li><a href="#" v-on:click="changeLanguage('fr')">Français</a></li>
				<li><a href="#" v-on:click="changeLanguage('ar')">عربي</a></li>
			  </ul>
			</div>
		  </div>
	</div>
	
	<div class="navbar-fixed" v-else>
		
	  <nav style="min-height:70px;">
		<div class="nav-wrapper blue darken-2">
			<a href="#" data-target="slide-out2" class="sidenav-trigger show-on-small show-on-medium-and-up left"><i class="material-icons white-text">menu</i></a>
			<a href="#" class="left" style="max-width:70px;"><img src="@/../public/img/COMESA_logo.fw.png" class="left z-depth-3 btn-floating btn-large" style="position:relaitve; top:3px; width:65px; height: 65px;"></a>
			<ul class="right">
				<li><router-link to="/register" class="black-text text-darken-4">{{ languages[3] }}</router-link></li>
				<li><router-link to="/login" class="black-text text-darken-4">{{ languages[4] }}</router-link></li>
				<li><a href="#language_modal2" class="modal-trigger"><i class="material-icons black-text text-darken-4">language</i></a></li>
			</ul>
		</div>
	  </nav>
		
		  <!-- Modal Structure -->
		  <div id="language_modal2" class="modal" style="width:280px;">
			<div class="modal-content">
			  <a href="#!" class="modal-close right"><i class="material-icons red-text text-darken-4">close</i></a>
			  <h5><b>Change Language / Changer de langue / تغيير اللغة</b></h5><hr>
			  <ul>
				<li><a href="#" v-on:click="changeLanguage('en')">English</a></li>
				<li><a href="#" v-on:click="changeLanguage('fr')">Français</a></li>
				<li><a href="#" v-on:click="changeLanguage('ar')">عربي</a></li>
			  </ul>
			</div>
		  </div>
	</div>
	
	
	
	<div id="slide-out2" class="sidenav row white darken-3">
		<ul class="row">
			<li class="row">
				<div class="user-view">
					<div class="background">
						<img src="@/../public/img/bushtracks-express-bridge-aerial.jpg">
					</div>
					<a href="#name"><span class="white-text name"></span></a>
					<a href="#email"><span class="white-text email"></span></a>
					<router-link class="sidenav-close waves-effect waves-light btn red" to="/login"><b>&nbsp;&nbsp;{{ languages[5] }}&nbsp;&nbsp;</b></router-link>
					<a href="#name"><span class="white-text name"></span></a>
					<a href="#email"><span class="white-text email"></span></a>
				</div>
			</li>
			<li class="row" style="padding:7px;">
				<ul class="row white">
					<li><router-link class="sidenav-close right" to="/">{{ languages[6] }} &nbsp;&nbsp;<i class="material-icons right">home</i></router-link></li>
					<li><router-link class="sidenav-close right" to="/news">{{ languages[7] }} &nbsp;&nbsp;<i class="bi bi-megaphone-fill right"></i></router-link></li>
					<li><router-link class="sidenav-close right" to="/guides">{{ languages[8] }} &nbsp;&nbsp;<i class="bi bi-file-pdf right bigger-tiny"></i></router-link></li>
					<li class="col s12 divider"></li>
				</ul>
			</li>
		</ul>
	</div>
	
			
	<div id="slide-out" class="sidenav row blue darken-2">
		<MySideNav :mynav="true" :show_background="true" :hidenav="false"  />
	</div>
	
</template>

<script>
import router from '@/router'
import M from '@cutls/materialize-css';
import IndexedDBHelper from '@/assets/js/IndexedDB'

import Requests from '@/components/Requests.vue'
import MySideNav from '@/components/MySideNav.vue'
import DashboardNotifications from '@/components/DashboardNotifications.vue'

import language from '@/configs/language.json';

export default {
  name: 'Nav',
  props: {
    msg: String
  },
  components: {
    MySideNav,
    DashboardNotifications,
	Requests
  },
  data() {return {db: null, //the database helper instance
		data_loaded: false,
		data_loading: true,
		token: null,
		my_key: 0,
		my_key2: 0,
		my_check_url: '/get/users/sessions/check',
		my_end_url: '/get/users/sessions/end',
		operation: '',
		sidebar: null,
		closed_nav: false,
			
			mylang: 'en',
			languages: [],
    };
  },
  methods: {
	async initFunctions(){
		let mylang = await this.db.getStorageItem("mylang");
		if(mylang=="" || mylang==null || mylang===undefined)
		{
			mylang = 'en';
		}
		this.mylang = mylang;
		this.languages = language[mylang].comp__nav;
	},
	initData(){
		this.initFunctions();
		this.getData();
		M.AutoInit();
	},
	async setToken(){
		this.token = await this.db.getStorageItem("token");
		//console.log(this.token);
		this.sidebar = await this.db.getStorageItem("sidebar");
	},
    async signOut() {
		this.token = await this.db.getStorageItem("token");
		this.my_end_url = '/get/users/sessions/end';
		this.my_key2 = this.my_key2+1;
		this.operation = 'end';
    },
    currentRouteName() {
      return this.$route.name;
    },
    async getData() {
		let token = await this.db.getStorageItem("token");
		this.token = token;
		this.my_check_url = '/get/users/sessions/check';
		
		this.my_key = this.my_key+1;
		this.operation = 'check';
    },
    openSideBar() {
      let sidebar = this.sidebar;
      if (sidebar == null || sidebar === undefined || sidebar === '') {
        let sidebar_id = document.getElementById("slide-out");
        let instance = M.Sidenav.init(sidebar_id, {});
        instance.open();
        this.db.setStorageItem("sidebar", 1);
      }
    },
    changeLanguage(mylang) {
        this.db.setStorageItem("mylang", mylang);
		location.reload();
    },
	getResponse(response){
		let my_res = response;
		console.log("test - "+this.token+" - "+this.operation+" - "+this.currentRouteName()+": ");
		console.log(my_res);
		if('data' in my_res && !(this.currentRouteName() == 'login' || this.currentRouteName() == 'home'))
		{
            if ('output' in my_res.data) {
              
              if (my_res.data.output.check == 1) {
                this.closed_nav = true;
				this.db.setStorageItem("defaults", my_res.data.output);
				M.AutoInit();
              } 
			  else 
			  {
                this.closed_nav = false;
                this.checkActiveSession();
              }
            } 
			else 
			{
              this.closed_nav = false;
              this.checkActiveSession();
            }

            this.data_loaded = true;
            this.data_loading = false;

		}
	},
	checkActiveSession(){
		if (this.currentRouteName() !== 'home' && this.currentRouteName() !== 'about' && this.currentRouteName() !== 'recovery' && this.currentRouteName() !== 'news' && this.currentRouteName() !== 'guides' && this.currentRouteName() !== 'register' && this.currentRouteName() !== 'register.user_org' && this.currentRouteName() !== 'register.signature_stamp' && this.currentRouteName() !== 'register.finalize' && this.currentRouteName() !== 'register.assignment_letter' && this.currentRouteName() !== 'login' && this.currentRouteName() !== 'verify') {
			this.db.deleteStorageItem("token");
			router.push('/login')
		}
	},
	getResponse2(response){
		let my_res = response;
		//console.log("test - "+this.token+" - "+this.operation+" - "+this.currentRouteName()+": ");
		
		if('data' in my_res)
		{
			//console.log(response.data);  
			
			this.db.deleteStorageItem("token");
			setTimeout(function () { router.push('/'); }, 1000);
		}
	}
  },
  computed: {},
  async created() { this.db = new IndexedDBHelper("my-eco-db", 1); await this.db.open(); }, async mounted(){ 
	  this.getData();
	  let token = await this.db.getStorageItem("token"); 
	  if(token==null || token==""){
		this.checkActiveSession();
	  } else { 
		this.initData() 
	  } 
  }
}
</script>